@import url(https://fonts.googleapis.com/css2?family=Inter:wght@600&display=swap);
.wallet-notification {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1rem;
    font-weight: 500;
    padding: 12px 15px;
}

.wallet-notification-error {
    background: rgba(235, 55, 66, 0.7);
}

.wallet-notification-info {
    background: rgba(78, 68, 206, 0.7);
}

.wallet-notification-success {
    background: rgba(33, 229, 111, 0.7);
}

@font-face {
  font-family: 'agathoda';
  src: local('agathoda'), url(/static/media/AGATHODA.45f173ff.TTF) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg'
                              */
}

body {
  margin: 0;
  font-family: 'Inter', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #303030;
  /* font-size: 50px; */
  font-weight: 500;
  padding: 12px 15px;
  color: grey;
}

nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  background: #2c2d30;
}

nav > div {
  display: flex;
}

nav > div > button {
  margin-left: 1rem;
}

#button {
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 50px;
  display: flex;
  justify-content: flex-end;
}

.wallet-adapter-button {
  color: rgb(131, 127, 127);
}

.wallet-adapter-button-trigger {
  background-color: rgb(52, 52, 52);
  border: 2px solid rgb(77, 77, 77);
}

.wallet-adapter-dropdown {
  margin-right: 20px;
}

.centerImage {
  display: flex;
  justify-content: center; /* horizontally center */
  align-items: center; /* vertically center */
  margin-bottom: 15px;
}

.centerImage img {
  max-width: 40%;
  max-height: 40%;
}

.centerText {
  display: grid;
  /* vertical-align: middle; */
  color: black;
  justify-content: center;
  align-items: center;
}

#symbols {
  font-family: agathoda;
  font-size: 20px;
  font-weight: bold;
  align-items: center;
  margin: auto;
}

@media (max-width: 600px) {
  nav > h1 {
    max-width: 90px;
  }

  .centerText {
    display: grid;
    font-size: 30px;
    color: black;
    justify-content: center; /* horizontally center */
    align-items: center; /* vertically center */
  }
}

